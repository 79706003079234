import React, { useState, useEffect } from "react";
import { Spinner, Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_URL_API } from "../environment";
import { FaClock } from "react-icons/fa";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Popup
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-arrowheads"; // Import leaflet-arrowheads plugin
import IconUrl from "../img/locmap.png";
import L from "leaflet";

// Define the formatDate function outside the component
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
};

// Define icons


const vehicleIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/2512/2512848.png", // Replace with your vehicle icon URL
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});
const clockIcon = L.icon({
  iconUrl: "https://img.icons8.com/ios-filled/50/4caf50/marker.png", // Replace with your icon URL
  iconSize: [40, 40], // Adjust dynamically based on screen size
  iconAnchor: [20, 40], // Anchor the icon
});



// Create Polyline with Arrowheads
function ArrowheadPolyline({ positions, color, highlighted }) {
  const map = useMap();

  useEffect(() => {
    const polyline = L.polyline(positions, {
      color: color,
      weight: highlighted ? 6 : 2,
      zIndex: highlighted ? 1000 : 1,
    }).addTo(map);

    if (highlighted) {
      polyline.arrowheads({
        size: "15px",
        frequency: "250px",
        fill: true,
        yawn: 40,
        color: 'green'
      });
    }

    return () => {
      map.removeLayer(polyline);
    };
  }, [map, positions, color, highlighted]);

  return null;
}

// Legend control
function LegendControl({ agentsData, setHighlightedAgentId, toggleExpand, isExpanded }) {
  const map = useMap();

  useEffect(() => {
    const legend = L.control({ position: "topright" });

    legend.onAdd = function () {
      const div = L.DomUtil.create("div", "info legend");
      div.style.width = 'auto'; // Set width to auto to accommodate content

      const title = document.createElement('div');
      title.style.display = 'flex';
      title.style.justifyContent = 'space-between';
      title.style.alignItems = 'center';
      const instruction = document.createElement('p');
      instruction.style.fontStyle = 'italic bold';
      instruction.style.fontSize = '14px ';
      instruction.style.fontWeight = 'bold';
      instruction.style.margin = '10px 0';
      instruction.innerText = 'Select any agent to view route';
      div.appendChild(instruction);
      const strong = document.createElement('strong');
      strong.innerText = 'All agents: ';
      title.appendChild(strong);

      const toggleIcon = document.createElement('i');
      toggleIcon.className = `fas ${isExpanded ? 'fa-compress' : 'fa-expand'}`;
      toggleIcon.style.cursor = 'pointer';
      toggleIcon.style.fontSize = '22px';
      toggleIcon.onclick = () => {
        toggleExpand();
      };
      title.appendChild(toggleIcon);

      div.appendChild(title);
      agentsData.forEach((agentData) => {
        const item = document.createElement('div');
        item.innerHTML = `
              <i style="background:${agentData.color}; width: 18px; height: 18px; display: inline-block; margin-right: 8px; border-radius: 3px; cursor: pointer;"
                onclick="window.highlightAgent('${agentData.agentName}')"></i> 
              ${agentData.agentName}
            `;
        div.appendChild(item);
      });
      return div;
    };

    legend.addTo(map);
    window.highlightAgent = (agentName) => {
      setHighlightedAgentId(agentName);
    };

    return () => {
      map.removeControl(legend);
    };
  }, [agentsData, map, setHighlightedAgentId, toggleExpand, isExpanded]);

  return null;
}
function DynamicSlot() {
  const [addslot, setAddSlot] = useState(false);
  const [startpincode, setStartPincode] = useState("");
  const [endpincode, setEndPincode] = useState("");
  const [dumppincode, setDumpPincode] = useState("");
  const [hotpointPincode, setHotPointPincode] = useState([]);
  const [starttime, setStartTime] = useState("");
  const [endtime, setEndTime] = useState("");
  const [cityIdchangeforroute, setCityIdChangeForRoute] = useState("");
  const [dumptime, setDumptime] = useState("");
  const [hotpointTime, setHotpointTime] = useState([]);
  const [additionalHotlistFields, setAdditionalHotlistFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [citychangeforroute, setCityChangeForRoute] = useState("");
  const [dateforroute, setDateForRoute] = useState(null);
  const [agentId, setAgentId] = useState("");
  const [agentdropdown, setAgentDropdown] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [markerValues, setMarkerValues] = useState({});
  const [agentColors, setAgentColors] = useState({});
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };
  const [selectedCityId, setSelectedCityId] = useState("65d5772df1cccd1633087da7");
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [cityPer, setCityPer] = useState([]);
  const [agent, setAgent] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [highlightedAgentId, setHighlightedAgentId] = useState(null);
  const [iconEnlarged, setIconEnlarged] = useState(false); // State to track icon size
  const [isExpanded, setIsExpanded] = useState(false);

  const fetchData = () => {
    axios
      .get(`${BASE_URL_API}routeslot/allSlotbyCity?cityid=${selectedCityId}&date=${selectedDate}&agentid=${selectedAgentId}`)
      .then((res) => {
        const usedColors = new Set(); // Set to track used colors
        const groupedData = res.data.data.reduce((acc, data) => {
          if (!acc[data.agent_id]) {
            let color;
            do {
              const hue = Math.random() * 360; // Random hue (0-360)
              const saturation = 50 + Math.random() * 50; // Saturation between 50% and 100%
              const lightness = 40 + Math.random() * 40; // Lightness between 40% and 80%
              color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
            } while (usedColors.has(color)); // Ensure the color is unique
            usedColors.add(color); // Mark the color as used

            acc[data.agent_id] = {
              routes: [],
              slots: data.slots,
              color: color,
              agentName: data.agent_name,

            };
          }
          if (data.routes && data.routes.length > 0) {
            acc[data.agent_id].routes.push(data.routes);
          }
          console.log("all slots is", data.slots)
          // const slots = data.slots; // Get the slots object
          // for (const key in slots) {
          //   if (slots.hasOwnProperty(key)) {
          //     acc[data.agent_id].slots.push(...slots[key]); // Flatten the slots into the array
          //   }
          // }
          return acc;
        }, {});
        const agentsArray = Object.values(groupedData);
        console.log("arrray all data is", agentsArray)
        setAgentsData(agentsArray);
      })
      .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "items/allCityPercent")
      .then((res) => setCityPer(res.data))
      .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "routeslot/getAgent/" + selectedCityId)
      .then((res) => setAgent(res.data.data))
      .catch((err) => console.log(err));
  };

  const isSameStartEnd = (route) => {
    return route[0][0] === route[route.length - 1][0] && route[0][1] === route[route.length - 1][1];
  };
  const formatdateYMD = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };
  const formatdateDMY = (dateString) => {
    const [year, month, date] = dateString.split("-");
    return `${date}-${month}-${year}`;
  };
  const handleDateChange = (date) => {
    // console.log(date);
    setSelectedDate(formatdateDMY(date));
  };
  useEffect(() => {
    fetchData();
  }, [selectedCityId, selectedDate, selectedAgentId]);
  const handleAddHotlistField = () => {
    setAdditionalHotlistFields((prevFields) => [
      ...prevFields,
      { pincode: "", time: "" },
    ]);
  };

  const handleHotlistFieldChange = (index, type, value) => {
    const updatedFields = [...additionalHotlistFields];
    updatedFields[index][type] = value;
    setAdditionalHotlistFields(updatedFields);
  };

  const handleHotlistTimeChange = (index, e) => {
    const formattedTime = handleTimeInput(e); // Use the handleTimeInput function to format the time
    const updatedFields = [...additionalHotlistFields];
    updatedFields[index].time = formattedTime; // Update the time field with formatted time
    setAdditionalHotlistFields(updatedFields); // Update the state
  };
  
  const formatDataForSecondAPI = (data) => {
    // Use Object.entries to get key-value pairs in original order
    return Object.entries(data).map(([zip, details]) => {
      console.log("details", details);
      return {
        zip: details.pincode,
        time: details.slots.map((slot) => slot.replace("-", " to ")),
        location: details.location,
      };
    });
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);

      const hotlistPincode = hotpointPincode.length === 0 ? [] : [hotpointPincode];
      const hotlistTime = hotpointTime.length === 0 ? [] : [hotpointTime];

      const allHotlistPincode = [
        ...hotlistPincode,
        ...additionalHotlistFields.map((field) => field.pincode),
      ];
      const allHotlistTime = [
        ...hotlistTime,
        ...additionalHotlistFields.map((field) => field.time),
      ];

      const data = {
        start_pincode: startpincode,
        end_pincode: endpincode,
        dump_pincode: dumppincode,
        end_time: endtime,
        dump_time: dumptime,
        start_time: starttime,
        Slots: [
          "08:45-09:30",
          "09:45-10:30",
          "10:30-11:00",
          "11:00-11:30",
          "11:30-12:00",
          "12:30-13:00",
          "13:30-14:30",
          "15:00-15:45",
          "16:00-16:45",
          "17:00-17:45",
        ],
        hotlist_pincode: allHotlistPincode,
        hotlist_time: allHotlistTime,
        cityName: citychangeforroute,
        graphMap: "Khordha District, Odisha, India",
      };

      // First API call
      const response = await axios.post("https://imagedetector.ceibagreen.com/api/scehdulingAlgorithms", data);
      console.log("Raw response from first API:", response.data);
      if (response.data && response.data.data) {
        const convertedSlotsData = response.data.data.map(([pincode, slots, location]) => ({ pincode, slots, location }));
        console.log("Response data after async call:", convertedSlotsData);
        const formattedData = formatDataForSecondAPI(convertedSlotsData);
        console.log("Formatted data for second API:", formattedData);

        const secondAPIBody = {
          month: dateforroute.toLocaleString("en-US", { month: "short" }),
          year: dateforroute.getFullYear().toString(),
          date: formatDate(dateforroute),
          time: formattedData,
          city: citychangeforroute,
          cityid: cityIdchangeforroute,
          agent_id: agentId,
          routes: response.data.road_path,
        };
        console.log("Payload for second API:", secondAPIBody);

        // Second API call
        const secondResponse = await axios.post(BASE_URL_API + "routeslot/createSlot", secondAPIBody);
        console.log("Second API response:", secondResponse.data);

        if (secondResponse.data.isSuccess) {
          window.alert("Successfully Added");
          fetchData();
          setStartPincode("");
          setEndPincode("");
          setDumpPincode("");
          setEndTime("");
          setDumptime("");
          setStartTime("");
          setAdditionalHotlistFields([]);
          setHotPointPincode([]);
          setHotpointTime([]);
        } else {
          window.alert("Error Occurred");
          fetchData();
          setStartPincode("");
          setEndPincode("");
          setDumpPincode("");
          setEndTime("");
          setDumptime("");
          setStartTime("");
          setAdditionalHotlistFields([]);
          setHotPointPincode([]);
          setHotpointTime([]);
        }
      } else {
        console.log("First API response missing data field.");
      }
    } catch (error) {
      console.error("Error:", error);
      window.alert("Error Occurred");
      fetchData();
      setStartPincode("");
      setEndPincode("");
      setDumpPincode("");
      setEndTime("");
      setDumptime("");
      setStartTime("");
      setAdditionalHotlistFields([]);
      setHotPointPincode([]);
      setHotpointTime([]);
    } finally {
      setLoading(false);
      setStartPincode("");
      setEndPincode("");
      setDumpPincode("");
      setEndTime("");
      setDumptime("");
      setStartTime("");
      setAdditionalHotlistFields([]);
      setHotPointPincode([]);
      setHotpointTime([]);
    }
  };
  const handleDeleteHotlistField = (index) => {
    const updatedFields = [...additionalHotlistFields];
    updatedFields.splice(index, 1);
    setAdditionalHotlistFields(updatedFields);
  };
  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    const selectedCity = cityPer.cities.find(
      (city) => city._id === selectedCityId
    );
    if (selectedCity) {
      axios
        .get(BASE_URL_API + "routeslot/getAgent/" + selectedCityId)
        .then((res) => {
          console.log(res);
          console.log(res.data);
          setAgentDropdown(res.data.data);
        })
        .catch((err) => console.log(err));
      setCityIdChangeForRoute(selectedCityId);
      setCityChangeForRoute(selectedCity.city);
    }
  };

  const handleTimeInput = (e) => {
    let input = e.target.value.replace(/[^0-9]/g, "");
    let formattedTime = "";
    if (input.length >= 2) {
      let hours = input.slice(0, 2); 
      let minutes = input.slice(2, 4); 

      if (parseInt(hours, 10) > 23) {
        hours = "23";
      }
      
      if (minutes && parseInt(minutes, 10) > 59) {
        minutes = "59";
      }
      
      
      formattedTime = hours + (minutes ? `:${minutes}` : "");
    } else {
      formattedTime = input;
    }


    return formattedTime;

  };

  const toggleIconSize = () => {
    setIconEnlarged(!iconEnlarged);
  };
  const toggleExpand = () => {
    setIsExpanded((prev) => {
      const newValue = !prev;
      console.log("Legend expanded state:", newValue); // Log the new state
      return newValue;
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row" style={{ height: "92vh" }}>
          <div
            className={isExpanded ? "col-md-12" : "col-md-7"}
            style={{ display: isExpanded ? 'block' : 'block', height: isExpanded ? 'auto' : '100%' }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <select
                  onChange={(e) => setSelectedCityId(e.target.value)}
                  value={selectedCityId}
                  className="form-select form-select-sm "
                  style={{
                    width: "150px",
                    height: "40px",
                    borderColor: "#73be44",
                  }}
                >
                  {cityPer && cityPer.cities
                    ? cityPer.cities.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.city}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div>
                <select
                  onChange={(e) => setSelectedAgentId(e.target.value)}
                  value={selectedAgentId}
                  className="form-select form-select-sm "
                  style={{
                    width: "150px",
                    height: "40px",
                    borderColor: "#73be44",
                  }}
                  defaultValue=""
                >
                  <option value="">
                    Select Agent
                  </option>
                  {Array.isArray(agent) &&
                    agent.map((agent) => (
                      <option key={agent._id} value={agent._id}>
                        {agent.name}
                      </option>
                    ))}
                </select>
              </div>
              <input
                type="date"
                value={formatdateYMD(selectedDate)}
                onChange={(e) => handleDateChange(e.target.value)}
              />
            </div>
            <div className="myMap mt-2" style={{ height: "92vh", width: "100%" }}>
              <MapContainer center={[20.304, 85.8397]} zoom={12} style={{ height: "100%", width: "100%" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <LegendControl agentsData={agentsData} setHighlightedAgentId={setHighlightedAgentId} toggleExpand={toggleExpand} isExpanded={isExpanded} />
                {agentsData.map((agentData, agentIndex) => (
                  <React.Fragment key={agentIndex}>
                    {agentData.routes.map((route, routeIndex) => (
                      <React.Fragment key={routeIndex}>
                        <ArrowheadPolyline
                          positions={route}
                          color={agentData.color}
                          opacity={highlightedAgentId === agentData.agentName ? 1 : 0.5}
                          highlighted={highlightedAgentId === agentData.agentName}
                        />
                        {isSameStartEnd(route) ? (
                          <Marker position={route[0]} icon={vehicleIcon}>
                            <Popup>Start/End</Popup>
                          </Marker>
                        ) : (
                          <>
                            <Marker position={route[0]} icon={vehicleIcon}>
                              <Popup>Start</Popup>
                            </Marker>
                            <Marker position={route[route.length - 1]} icon={vehicleIcon}>
                              <Popup>End</Popup>
                            </Marker>
                          </>
                        )}
                        {/* <Marker position={route[0]} icon={startIcon} />
                          <Marker position={route[route.length - 1]} icon={endIcon} /> */}
                        {/* {Object.keys(agentData.slots).map((pincode) => (
                            agentData.slots[pincode].map((slot, index) => (
                              <Marker key={index} position={slot.location} icon={clockIcon} zIndexOffset={1000} style={{ color: 'green' }} >
                                <Popup>
                                  <strong>Pincode:</strong> {pincode} <br />
                                  <strong>Time Slots:</strong>
                                  <ul>
                                    {slot.time.map((timeSlot, idx) => (
                                      <li key={idx}>{timeSlot}</li>
                                    ))}
                                  </ul>
                                </Popup>
                              </Marker>
                            ))
                          ))} */}
                        {agentsData
                          .filter(agentData => agentData.agentName === highlightedAgentId) // Filter by the highlighted agent
                          .map((agentData, agentIndex) => (
                            Object.keys(agentData.slots).map((pincode) => (
                              agentData.slots[pincode].map((slot, index) => (
                                <Marker key={index} position={slot.location} icon={clockIcon} zIndexOffset={1000} >
                                  <Popup>
                                    <strong>Pincode:</strong> {pincode} <br />
                                    <strong>Time Slots:</strong>
                                    <ul>
                                      {slot.time.map((timeSlot, idx) => (
                                        <li key={idx}>{timeSlot}</li>
                                      ))}
                                    </ul>
                                  </Popup>
                                </Marker>
                              ))
                            ))
                          ))}

                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </MapContainer>
            </div>
          </div>
          <div
            className="col-md-5"
            style={{ display: isExpanded ? 'none' : 'block', height: isExpanded ? '0' : 'auto' }}
          >

            <div className="d-flex justify-content-center mt-3 mb-3">
              <h6 style={{ color: "#73be44" }}>Create Route</h6>
            </div>
            <div className="d-flex justify-content-between  py-2">
              <div>
                <select
                  id="cityDropdown"
                  className="form-select form-select-sm "
                  defaultValue=""
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="" disabled>
                    Select City
                  </option>
                  {cityPer && cityPer.cities
                    ? cityPer.cities.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.city}
                      </option>
                    ))
                    : null}
                </select>
              </div>
              <div>
                <select
                  id="agentDropdown"
                  className="form-select form-select-sm "
                  defaultValue=""
                  onChange={(e) => {
                    setAgentId(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Select Agent
                  </option>
                  {Array.isArray(agentdropdown) &&
                    agentdropdown.map((agent) => (
                      <option key={agent._id} value={agent._id}>
                        {agent.name}
                      </option>
                    ))}
                </select>
              </div>
              <input
                type="date"
                onChange={(e) => setDateForRoute(new Date(e.target.value))}
              />
            </div>
            <div style={{ fontSize: "14px" }}>
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Start Pincode </Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={startpincode}
                            onChange={(e) => setStartPincode(e.target.value)}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Start Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                        <Form.Control
                          type="text"
                          className="modalTextField"
                          style={{ paddingRight: "6px" }}
                          value={starttime} // assuming startTime is the state that holds the input value
                          maxLength={5} // 4 digits + colon
                          placeholder="HH:MM"
                          onChange={(e) => setStartTime(handleTimeInput(e))}
                          required
                        />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>End Pincode</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={endpincode}
                            onChange={(e) => {
                              setEndPincode(e.target.value);
                            }}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>End Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            className="modalTextField"
                            style={{ paddingRight: "6px" }}
                            value={endtime}
                            maxLength={5} 
                            placeholder="HH:MM"
                            onChange={(e) => setEndTime(handleTimeInput(e)) }
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Dump Pincode</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={dumppincode}
                            onChange={(e) => {
                              setDumpPincode(e.target.value);
                            }}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Dump Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            className="modalTextField"
                            style={{ paddingRight: "6px" }}
                            value={dumptime}
                            maxLength={5} 
                            placeholder="HH:MM"
                            onChange={(e) => setDumptime(handleTimeInput(e)) }
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>HotList Pincode</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={hotpointPincode}
                            onChange={(e) => {
                              setHotPointPincode(e.target.value);
                            }}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>HotList Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="HH:MM"
                            className="modalTextField"
                            style={{ paddingRight: "6px" }}
                            value={hotpointTime}
                            maxLength={5}
                            onChange={(e) => setHotpointTime(handleTimeInput(e))}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                {additionalHotlistFields.map((field, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-md-5">
                        <Form.Group controlId={`hotlistPin${index}`}>
                          <Row>
                            <Col xs={6} md={5}>
                              <Form.Label>HotList Pincode</Form.Label>
                            </Col>
                            <Col xs={12} md={7}>
                              <Form.Control
                                type="text"
                                placeholder="Enter Pin"
                                name="PIN"
                                value={field.pincode}
                                onChange={(e) =>
                                  handleHotlistFieldChange(
                                    index,
                                    "pincode",
                                    e.target.value
                                  )
                                }
                                maxLength={6}
                                autoFocus
                                required
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId={`hotlistTime${index}`}>
                          <Row>
                            <Col xs={6} md={5}>
                              <Form.Label>HotList Time</Form.Label>
                            </Col>
                            <Col xs={12} md={7}>
                            <Form.Control
                              type="text"
                              placeholder="HH:MM"
                              className="modalTextField"
                              value={field.time}
                              maxLength={5}
                              onChange={(e) => handleHotlistTimeChange(index, e)} // Now using the updated function
                              required
                            />
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                      <div className="col-md-1">
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteHotlistField(index)}
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="d-flex justify-content-end mt-3">
                  <Button variant="primary" onClick={handleAddHotlistField}>
                    Add HotList
                  </Button>
                </div>
              </Form>
            </div>
            <div className="d-flex justify-content-center mt-3">
              {loading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Button variant="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicSlot;
